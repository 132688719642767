import React from "react"
import logo from '../images/logo.jpg'
import { Link } from 'gatsby'
const Header = () => (
  <header>
    <nav>
      <div className="container">
        <div className="flex-row">
          <div className="flex-small logo-wrapper">
            <Link to="/">
              <img className="logo" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="flex-small header-contact">
            <span>Got Questions? <br /></span>
            <span>Call us now at</span>
            <a href="tel:8006197022">800-619-7022</a>
          </div>
        </div>
      </div>
    </nav>
  </header>
)

export default Header

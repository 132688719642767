/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, {Component} from "react"
import {connect} from 'react-redux'
import { setAffParams, setIpAddress, setUserAgent, setUniversalLeadId } from '../state/action'
import Footer from "./footer"
import Header from "./header"
class Layout extends Component {

  searchToObject = (search) => {
    // window check is necessary for production build step
      return search.substring(1).split('&').reduce((result, value) => {
				const parts = value.split('=');
				if (parts[0]) result[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
				return result;
			}, {});
  }

  componentDidMount(){
    this.props.setUserAgent(navigator.userAgent)
    const params = this.searchToObject(this.props.location.search)
    this.props.setAffParams(params)
  }

  render () {
    return (
      <>
        <Header />
          <main>
            {this.props.children}
          </main>
        <Footer />
      </>
    )
  }

}  

const mapDispatchToProps = dispatch => {
  return {
    setAffParams: params => dispatch(setAffParams(params)),
    setIpAddress: ip_address => dispatch(setIpAddress(ip_address)),
    setUniversalLeadId: universal_leadid => dispatch(setUniversalLeadId(universal_leadid)),
    setUserAgent: user_agent => dispatch(setUserAgent(user_agent)),
  }
}

export default connect( null, mapDispatchToProps )( Layout )

import React from 'react'
import { Link } from 'gatsby'
const Footer = ( ) => (
  <footer>
    <div className="container">
      <div className="flex-row">
        <div className="flex-small">
        <h3>Health Insurance</h3>
        <p>Horizon Health is now offering access to health insurance plans in all 50 states. We specialize in connecting consumers with low-cost and subsidized plans under the ACA. Up to 80% of Americans qualify for some sort of assistance with their health insurance premiums. Call us to speak to one of our specialists or fill out our short form to compare plans in all tiers - Bronze to Platinum. Ask us about income-based subsidies, tax rebates, and more.</p>
        </div>
      </div>
      <div className="flex-row">
        <div className="flex-small">
          <span className="copyright">© Horizon Health Insurance 2019. All rights reserved.</span>
          <Link to="/" className="footer-link"> Privacy Policy </Link> |
          <Link to="/" className="footer-link"> Terms of Use </Link> |
          <Link to="/" className="footer-link"> About Us </Link>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer;